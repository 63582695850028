var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"contenedor-main"},[_c('v-row',[_c('v-container',{staticClass:"contenedor-Stools"},[_c('v-row',{staticClass:"rowNop"},[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-autocomplete',{staticClass:"auto",attrs:{"items":_vm.estudiantes,"loading":_vm.isLoading,"search-input":_vm.search,"hide-details":"","hide-no-data":"","return-object":"","rounded":"","label":"Busqueda de usuario"},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},model:{value:(_vm.busquedaId),callback:function ($$v) {_vm.busquedaId=$$v},expression:"busquedaId"}})],1)],1)],1)],1),_c('v-row',[(_vm.isLoading == true)?_c('tool-bar-nav',{attrs:{"pageRow":{ value: _vm.pageLengthPer, type: 'resumenRHeigth' },"refreshBottom":{ badgeText: 'Reestablecer cols' },"exportBottom":{
        badgeText: 'Exportar tabla',
        nameDoc: 'Ventas',
      },"histBottom":{ show: _vm.rigthClick, data: _vm.historial },"colorBottom":{ show: _vm.rigthClick },"selectedRow":_vm.onSelected,"grid":{ idGrid: _vm.$refs.grid }},on:{"texto-tool":function($event){return _vm.eventosTextTool($event)},"color-item":function($event){return _vm.eventosColor($event, _vm.asignarColor)}}}):_vm._e(),_c('v-container',{directives:[{name:"show",rawName:"v-show",value:(_vm.busquedaId != null && _vm.mostrarTable == true),expression:"busquedaId != null && mostrarTable == true"}],staticClass:"contenedor-tabla"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.jsondata,"dense":"","no-results-text":"Sin resultados","header-props":{ sortByText: 'Organizar por' },"footer-props":{
          'items-per-page-text': 'items por página',
          'items-per-page-all-text': 'Todos',
        }},scopedSlots:_vm._u([{key:"item.totalDeuda",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.totalDeuda))+" ")]}},{key:"item.materias",fn:function(ref){
        var item = ref.item;
return [_c('v-list-group',{attrs:{"value":false,"no-action":"","sub-group":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Materias:")])],1)]},proxy:true}],null,true)},_vm._l((item.resumen),function(data,index){return _c('v-list-item',{key:data + index},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(data["materia"]))]),_vm._l((data['notas']),function(not){return _c('v-list-item-subtitle',{key:not},[_vm._v(" "+_vm._s(not["nota"])+" "),_c('span',{staticClass:"textp"},[_vm._v(" "+_vm._s(not["valores"]))]),_vm._v(" - Vinculada a: "),_c('span',{staticClass:"textp"},[_vm._v(" "+_vm._s(not["relacion"])+" ")]),_vm._v(" con un valor de: "),_c('span',{staticClass:"textp"},[_vm._v(" "+_vm._s(not["valor"])+" ")])])})],2)],1)}),1)]}},{key:"item.pagos",fn:function(ref){
        var item = ref.item;
return [_c('v-list-group',{attrs:{"value":false,"no-action":"","sub-group":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Pagos:")])],1)]},proxy:true}],null,true)},_vm._l((item.pagos),function(data,index){return _c('v-list-item',{key:data + index},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Abono "+_vm._s(index + 1))]),_c('v-list-item-subtitle',[_vm._v(" Fecha de pago: "+_vm._s(_vm.$moment(data["fechaPago"]).format("DD/MM/YYYY")))]),_c('v-list-item-subtitle',[_vm._v(" Cantidad: "+_vm._s(_vm._f("currency")(data["cantidad"])))])],1)],1)}),1)]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }