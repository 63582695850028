<template>
  <v-container class="contenedor-main">
    <v-row>
      <v-container class="contenedor-Stools">
        <v-row class="rowNop">
          <v-col cols="12" md="3">
            <v-autocomplete
              class="auto"
              v-model="busquedaId"
              :items="estudiantes"
              :loading="isLoading"
              :search-input.sync="search"
              hide-details
              hide-no-data
              return-object
              rounded
              label="Busqueda de usuario"
            >
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
    <v-row>
      <tool-bar-nav
        v-if="isLoading == true"
        :pageRow="{ value: pageLengthPer, type: 'resumenRHeigth' }"
        :refreshBottom="{ badgeText: 'Reestablecer cols' }"
        :exportBottom="{
          badgeText: 'Exportar tabla',
          nameDoc: 'Ventas',
        }"
        :histBottom="{ show: rigthClick, data: historial }"
        :colorBottom="{ show: rigthClick }"
        :selectedRow="onSelected"
        :grid="{ idGrid: $refs.grid }"
        v-on:texto-tool="eventosTextTool($event)"
        v-on:color-item="eventosColor($event, asignarColor)"
      >
      </tool-bar-nav>
      <v-container
        class="contenedor-tabla"
        v-show="busquedaId != null && mostrarTable == true"
      >
        <v-data-table
          :headers="headers"
          :items="jsondata"
          dense
          no-results-text="Sin resultados"
          :header-props="{ sortByText: 'Organizar por' }"
          :footer-props="{
            'items-per-page-text': 'items por página',
            'items-per-page-all-text': 'Todos',
          }"
        >
          <template v-slot:[`item.totalDeuda`]="{ item }">
            {{ item.totalDeuda | currency }}
          </template>
          <template v-slot:[`item.materias`]="{ item }">
            <v-list-group :value="false" no-action sub-group>
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>Materias:</v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item
                v-for="(data, index) in item.resumen"
                :key="data + index"
              >
                <v-list-item-content>
                  <v-list-item-title> {{ data["materia"] }}</v-list-item-title>
                  <v-list-item-subtitle
                    v-for="not in data['notas']"
                    v-bind:key="not"
                  >
                    {{ not["nota"] }}
                    <span class="textp"> {{ not["valores"] }}</span> - Vinculada
                    a: <span class="textp"> {{ not["relacion"] }} </span> con un
                    valor de:
                    <span class="textp">
                      {{ not["valor"] }}
                    </span>
                  </v-list-item-subtitle>
                  <!-- <v-list-item-subtitle> {{ data }}</v-list-item-subtitle> -->
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </template>
          <template v-slot:[`item.pagos`]="{ item }">
            <v-list-group :value="false" no-action sub-group>
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>Pagos:</v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item
                v-for="(data, index) in item.pagos"
                :key="data + index"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    Abono
                    {{ index + 1 }}</v-list-item-title
                  >
                  <v-list-item-subtitle>
                    Fecha de pago:
                    {{
                      $moment(data["fechaPago"]).format("DD/MM/YYYY")
                    }}</v-list-item-subtitle
                  >
                  <v-list-item-subtitle>
                    Cantidad:
                    {{ data["cantidad"] | currency }}</v-list-item-subtitle
                  >
                  <!-- <v-list-item-subtitle> {{ data }}</v-list-item-subtitle> -->
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </template>
        </v-data-table>
      </v-container>
    </v-row>
  </v-container>
</template>

<script>
import firebase from "firebase";
import { host } from "./Tools/variables";
import axios from "axios";

import tablesMixin from "../mixins/tablas";
import infoSelected from "./Tools/itemSelected";
import ToolBarNav from "./component/ToolBarNav.vue";

export default {
  mixins: [tablesMixin],

  components: {
    ToolBarNav,
  },

  data() {
    return {
      busquedaId: "",
      pageLengthPer: 20,
      mostrarTable: false,
      jsondata: [],
      usuariosList: [],
      onSelected: null,
      isLoading: false,
      estudiantes: [],
      search: null,
      headers: [
        {
          text: "Periodo",
          align: "start",
          value: "periodo",
        },
        {
          text: "Deuda total",
          align: "start",
          value: "totalDeuda",
        },
        {
          text: "Materias",
          align: "start",
          value: "materias",
        },
        {
          text: "Pagos",
          align: "start",
          value: "pagos",
        },
      ],
      // click derecho
      rigthClick: false,
      // color
      asignarColor: {
        name: null,
        color: null,
        idItem: null,
      },
      haveCellsColor: false,
      // hitorial
      historial: {
        nombreCol: null,
        id: null,
      },
    };
  },

  async created() {
    await this.cargueColors();
  },

  // watch: {

  // },

  watch: {
    busquedaId: function (v) {
      if (v != null) {
        this.initialize();
      }
    },
    search(val) {
      this.isLoading = true;
      this.estudiantes = [];
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          const fd = new FormData();
          fd.append("token", idToken);
          fd.append("busqueda", val);
          axios.post(host + "/buscaEstudiante", fd).then((result) => {
            this.estudiantes = result.data.data;
          });
        })
        .catch(function (error) {
          this.$alert("Error " + error.message);
        })
        .finally(() => (this.isLoading = false));
    },
  },

  mounted() {
    document.title = "Ventas";
    this.usuariosGet();
  },
  methods: {
    onSelect() {
      this.onSelected = infoSelected(this.$refs.grid, "_id");
      this.rigthClick = false;
    },
    async initialize() {
      this.mostrarTable = false;
      this.listaColumnas = [];
      this.columnasMostrar = [];
      this.mt = false;
      await this.cargueColors();
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          const fd = new FormData();
          fd.append("token", idToken);
          fd.append("usuario", this.busquedaId);
          axios.post(host + "/resumenEstudiante", fd).then((result) => {
            let data = result.data.data;
            if (result.data.text == "OK") {
              this.pageLength = data.length;
              for (let i = 0, len = data.length; i < len; i++) {
                var tD = Intl.NumberFormat("de-DE").format(
                  data[i]["totalDeuda"]
                );
                data[i]["Total deuda"] = tD;
                data[i]["resumen"] = [];
                for (
                  let m = 0, len1 = data[i]["materias"].length;
                  m < len1;
                  m++
                ) {
                  var materias = data[i]["materias"][m];
                  data[i]["resumen"].push({
                    notas: [],
                    materia: materias.nombre,
                  });
                  var notas = materias["notas"];
                  var porcentajeNotas = materias["porcentajeNotas"];
                  for (let n = 0, lenn = notas.length; n < lenn; n++) {
                    var nota = notas[n];
                    var relacionNotas = Object.values(
                      data[i]["materias"][m]["relacionNotas"]
                    );
                    var valor = "-";
                    var anotacion = "-";
                    if (relacionNotas) {
                      for (
                        let rn = 0, lenm = relacionNotas.length;
                        rn < lenm;
                        rn++
                      ) {
                        const element = relacionNotas[rn];
                        if (element["Nota"] == nota["nota"]) {
                          var anotaciones =
                            data[i]["materias"][m]["anotaciones"];
                          var anots = anotaciones.find(
                            (e) => e.nombre == element["Anotation"]
                          );
                          anotacion = anots["nombre"];
                          valor = anots["valor"];
                        }
                      }
                      data[i]["resumen"].find((e) => {
                        if (e.materia == materias.nombre) {
                          var valNota =
                            nota["valor"] == null ? "-" : nota["valor"];
                          var valNotaPer = porcentajeNotas[n];
                          e["notas"].push({
                            nota: "Nota " + nota["nota"] + ": ",
                            valores: valNota + "/" + valNotaPer,
                            relacion: anotacion,
                            valor: valor,
                          });
                        }
                      });
                    }
                  }
                }
              }
              this.jsondata = data;
              console.log("jsondata");
              console.log(this.jsondata);
              this.mostrarTable = true;
              this.cargueCol();
            } else {
              this.$alert(result.data.text);
            }
          });
          this.mt = true;
        })
        .catch(function (error) {
          this.$alert("Error " + error.message);
        });
    },
    // search() {
    //   this.estudiantes = [];
    //   firebase
    //     .auth()
    //     .currentUser.getIdToken(/* forceRefresh */ true)
    //     .then((idToken) => {
    //       const fd = new FormData();
    //       fd.append("token", idToken);
    //       fd.append("busqueda", this.busquedaId);
    //       axios.post(host + "/buscaEstudiante", fd).then((result) => {
    //         this.estudiantes = result.data.data;
    //       });
    //     })
    //     .catch(function (error) {
    //       this.$alert("Error " + error.message);
    //     });
    // },

    // }
  },
};
</script>
<style>
.contenedor-Stools {
  min-width: 100%;
  margin: 0;
  width: 100%;
  display: flex;
  padding: 0 !important;
  border: 1px #8884 solid;
  z-index: 100;
}
.auto {
  margin-bottom: 3% !important;
}

.textp {
  color: #0d47a1 !important;
  font-weight: 700 !important;
  font-size: 15px;
}
</style>